import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { FaSearch, FaAngleDown, FaAngleRight } from 'react-icons/fa'
import { MdSettingsOverscan } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { cityAction } from '../../_actions/city.action'
import HeaderJson from '../../components/Header/Header.json'
import { useNavigate, useParams } from 'react-router'
import { logoutFunction } from '../../_helpers'
import Paths from "./Paths.json"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const DashBoardHeader = ({ isOpen, setIsOpen, handleOnChange }) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const params = useParams()

  const menuRef = useRef(null)

  const [darkMode, setDarkMode] = useState(false)
  const [selectCity, setSelectCity] = useState('')
  const [searchText, setSearchText] = useState('')

  const [userProfile, setUserProfile] = useState()
  const navigate = useNavigate()
  const [path, setPath] = useState([])

  useEffect(() => {
    let cityDetails = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: 1,
      size: 10
    }

    setDarkMode(() => {
      return JSON.parse(window.sessionStorage.getItem('management'))
    })
    dispatch(cityAction.cityGet(cityDetails))
  }, [dispatch])

  useEffect(() => {
    setUserProfile(selector?.MyProfile?.getAdminProfile?.data)
  }, [selector])

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
    if (darkMode === true) {
      navigate('/app/dashboard-cb')
    } else {
      navigate('/app/dashboard')
    }
    window.sessionStorage.setItem('management', !darkMode)
  }

  const toggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  const handleCitySelect = city => {
    setSelectCity(city.name)
    setIsOpen(false)
  }

  const handleLogout = () => {
    logoutFunction()
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const toggleSearch = () => {
    var element = document.getElementById('myDiv')
    if (element.style.display === 'none') {
      element.style.display = 'block'
    } else {
      element.style.display = 'none'
    }
  }

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        )
      })
    } else {
      document.exitFullscreen()
    }
  }

  const searchByLabel = (array, searchTerm) => {
    const term = searchTerm.toLowerCase()
    return array.filter(item => item.label.toLowerCase().includes(term))
  }

  useEffect(() => {
    if (searchText !== '') {
      setPath(() => searchByLabel(Paths, searchText))
    } else {
      setPath([])
    }
  }, [searchText])



  return (
    <Fragment>
      <section className=' bg-[#ffffff] lg:block hidden border-r border pl-4 h-20'>
        <div className='w-full py-3 px-10 flex justify-between items-center'>
          <div className='flex items-center gap-10'>
            <div>
              <h1 className='text-2xl font-[800] uppercase'>Dashboard</h1>
              {params['*'] === 'dashboard-cb' && (
                <p className='text-[#7E7E7E]'>Non Management Modal</p>
              )}
            </div>
            <div>
              {JSON.parse(window.sessionStorage.getItem('adminuser'))
                ?.roleAccess?.roleId === 1 && (
                  <label class='inline-flex items-center cursor-pointer'>
                    <input
                      onClick={toggleDarkMode}
                      type='checkbox'
                      value=''
                      class='sr-only peer'
                      checked
                    />
                    <div class="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                )}
            </div>
          </div>
          <div className='flex items-center gap-6'>
            <div className='search_bar  relative text-center flex justify-start lg:justify-start  lg:w-auto pt-2 lg:pt-0'>
              {searchText === '' && (
                <div className='absolute inset-y-0 left-0 flex items-center pl-6 pb-3'>
                  <FaSearch className='text-gray-400 mt-3 search_icon' />
                </div>
              )}
              <input
                type='search'
                className={`border-2 w-full lg:w-80 h-9 ps-6 rounded-lg py-1 px-6 ${searchText ? 'search-active' : ''
                  }`}
                onChange={handleSearch}
                placeholder='Universal Search'
                id='search'
                value={searchText}
              />
            </div>
            {searchText && (
              <div className='absolute w-72 bg-white border border-gray-300 rounded-lg shadow-lg z-10 top-16 over'>
                {path.length ? (
                  <ul className='divide-y divide-gray-300 text-[#023565]'>
                    {path.slice(0, 5).map((item, index) => (
                      <li
                        className='flex justify-between items-center cursor-pointer hover:bg-gray-100 hover:text-[#E4006F]  p-3 rounded-t-lg'
                        key={index}
                        onClick={() => navigate(item.location)}
                      >
                        <span className=' text-sm font-semibold'>
                          {item.label}
                        </span>
                        <FaAngleRight className='hover:text-[#C60E6B]' />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <li className='flex justify-between items-center cursor-pointer hover:bg-gray-100 hover:text-[#E4006F]  p-3 rounded-t-lg'>
                    <span className=' text-sm font-semibold'>No matches.</span>
                  </li>
                )}
              </div>
            )}



            <div className='flex relative  city_select cursor-pointer items-center'>
              {/* <select className="flex items-center justify-center bg-white border-2 text-[#D9D9D9] rounded-lg w-full lg:w-36 px-4 h-9">
              <option className="text-black align-middle" value={""}>
                {" "}
                All Cities <FaAngleDown className="ml-3" color="#D9D9D9" />
              </option>
              {selector?.Citys?.cityGetList?.map((city, id) => (
                <option className="text-black capitalize" value="" key={id}>
                  {" "}
                  {city.name}
                </option>
              ))}
            </select> */}
              <span className='mx-2'>
                <img src='/communications 1.png' alt='communication icon' />
              </span>
            </div>
          </div>
          <div className='flex items-center gap-4'>
            <div className='full_screen px-2 flex items-center gap-2'>
              <button className='text-2xl' onClick={handleFullscreen}>
                <MdSettingsOverscan />
              </button>
              <div className='font-md admin_name capitalize'>
                {userProfile?.userName || HeaderJson['Admin-name']}
              </div>
            </div>
            <Menu as='div' className='relative inline-block text-left'>
              {({ open }) => (
                <>
                  <div className='relative'>
                    <Menu.Button className='flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline'>
                      <img
                        src={
                          userProfile?.image || `/${HeaderJson['Admin-image']}`
                        }
                        alt=''
                        className='rounded-full flex justify-center items-center  w-10 h-10 ring-2'
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items
                      static
                      className='origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                    >
                      <div className='py-1'>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={handleLogout}
                              className={classNames(
                                active
                                  ? 'bg-shine text-gray-900'
                                  : 'text-gray-900',
                                'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium hover:bg-slate-600 hover:text-white'
                              )}
                            >
                              Logout
                            </span>
                          )}
                        </Menu.Item>
                        <div>
                          <ul>
                            <li className='tab_view lg:hidden md:block xl:hidden text-center'>
                              <div className='flex relative d-block cursor-pointer items-center px-4'>
                                <p
                                  className='flex justify-center'
                                  onClick={toggleDropDown}
                                >
                                  {selectCity || 'All Cities'}
                                  <FaAngleDown className='ml-3 mt-1 hi' />
                                </p>
                                {isOpen && (
                                  <div
                                    ref={menuRef}
                                    className='absolute cursor-pointer city_name'
                                  >
                                    {Array.isArray(
                                      selector.Citys.cityGetList
                                    ) &&
                                      selector.Citys.cityGetList.length > 0 &&
                                      selector.Citys.cityGetList.map(
                                        (city, id) => (
                                          <p
                                            className='cursor-pointer capitalize text-black'
                                            key={id}
                                            onClick={() =>
                                              handleCitySelect(city)
                                            }
                                          >
                                            {city.cityName}
                                          </p>
                                        )
                                      )}
                                  </div>
                                )}
                              </div>
                            </li>
                            <li className='lg:hidden md:block'>
                              <p
                                className='search-input ps-4'
                                onClick={toggleSearch}
                              >
                                search
                              </p>
                              <div
                                className='relative searchInput'
                                hidden
                                id='myDiv'
                              >
                                <input
                                  type='search'
                                  className={`bg-gray-300 ps-6 rounded-md py-1 mt-1 ${searchText ? 'search-active' : ''
                                    }`}
                                  onChange={handleOnChange}
                                  placeholder='Universal search'
                                  id='search'
                                  value={searchText}
                                />
                                {searchText === '' && (
                                  <div className='absolute inset-y-0 left-0 flex items-center pl-6'>
                                    <FaSearch className='text-gray-400' />
                                  </div>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </section>

      <section className='bg-white lg:hidden block'>
        <div className='flex justify-between items-center px-4'>
          <div></div>
          <div className='mobile_view_header_img flex justify-center items-center'></div>
          <div>
            <div class='search-container'>
              <form action='/search' method='get'>
                <input
                  class='search expandright placeholder-white'
                  id='searchright'
                  type='search'
                  name='q'
                  placeholder='Search'
                />
                <label class='button-mob searchbutton' for='searchright'>
                  <span class='mglass'>&#9906;</span>
                </label>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default DashBoardHeader
