import React from 'react';

const ImagePreviewModal = ({ showWarning, handleClose, image }) => {
    // If the modal is not visible, render nothing

    return showWarning && (
        <div
            id="default-modal"
            tabindex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center w-full h-screen overflow-y-auto bg-black bg-opacity-50"
        >
            <div className="relative w-full  max-w-2xl p-4 max-h-full">
                {/* Modal content */}
                <div className="relative bg-white  rounded-lg shadow ">
                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Image Preview
                        </h3>
                        <button
                            type="button"
                            onClick={() => handleClose()}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white hover:cursor-pointer"
                        >
                            <svg 
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* Modal body */}
                    <div className='flex justify-center'>
                        <img  src={image} alt='' className='max-h-[340px]' />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ImagePreviewModal;
