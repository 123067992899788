import React from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { truncateText } from '../../../_helpers'
import { FaEdit } from 'react-icons/fa'
import { IoAddOutline } from 'react-icons/io5'
import Button from '../../Notification/Button'
import moment from 'moment'
function StudentDetail ({
  setViewStudentModal,
  viewStudentModal,
  setStudentListData,
  studentListData,
  isAction,
  openModal
}) {
  return (
    viewStudentModal && (
      <>
        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
          <div className='relative w-11/12 mx-auto lg:w-[1024px]'>
            <button
              className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
              onClick={() => {
                setViewStudentModal(false)
                setStudentListData([])
              }}
            >
              <span>
                <MdOutlineClose />
              </span>{' '}
              Close
            </button>
            <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h2 className='text-lg font-semibold'>Student Details </h2>
                {isAction ? (
                  <div
                    className='flex w-40 items-center font-bold text-[#C60E6B] border bg-white rounded-lg px-2 py-2 hover:text-gray-900'
                    onClick={() => openModal({})}
                  >
                    <span>
                      <IoAddOutline className='text-xl mx-1' />
                    </span>
                    <Button name='Add Student' />
                  </div>
                ) : null}
              </div>
              <div className='relative lg:h-[400px] overflow-y-auto'>
                <div className='overflow-x-auto overflow-hidden p-4'>
                  <table className='table_full w-full border border-gray-200'>
                    <thead>
                      <tr className='text-center bg-gray-100 border-b'>
                        <th className='py-2 px-4 text-left border whitespace-nowrap'>
                          Student Name
                        </th>
                        <th className='py-2 px-4 text-left border whitespace-nowrap'>
                          Gender
                        </th>
                        <th className='py-2 px-4 text-left border whitespace-nowrap'>
                          Class
                        </th>
                        <th className='py-2 px-4 text-left border whitespace-nowrap'>
                          Subject
                        </th>
                        <th className='py-2 px-4 text-left border whitespace-nowrap'>
                          School Name
                        </th>
                        <th className='py-2 px-4 text-left border whitespace-nowrap'>
                          Board
                        </th>
                        <th className='py-2 px-4 text-left border whitespace-nowrap'>
                          D-O-B
                        </th>
                        {isAction ? (
                          <>
                            <th className='py-2 px-4 text-left border whitespace-nowrap'>
                              Status
                            </th>
                            <th className='py-2 px-4 text-left border whitespace-nowrap'>
                              Action
                            </th>
                          </>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {studentListData && studentListData.length > 0 ? (
                        studentListData?.map((std, id) => (
                          <tr key={id} className='border-b capitalize'>
                            <td className='py-2 px-4 border whitespace-nowrap capitalize'>
                              {std?.studentDetails?.studentFullName ?? 'N/A'}
                            </td>
                            <td className='py-2 px-4 border whitespace-nowrap capitalize'>
                              {std?.studentDetails?.gender
                                ? String(
                                    std?.studentDetails?.gender
                                  ).toLowerCase()
                                : 'N/A'}
                            </td>
                            <td className='py-2 px-4 border whitespace-nowrap capitalize'>
                              {std?.classDetails?.name
                                ? String(std?.classDetails?.name.toUpperCase())
                                : 'N/A'}
                            </td>
                            <td
                              className='py-2 px-4 border whitespace-nowrap capitalize cursor-pointer'
                              title={std?.subjectDetails
                                .map(ele => ele.name)
                                .join(',')}
                            >
                              {std?.subjectDetails &&
                              std?.subjectDetails.length > 0
                                ? truncateText(
                                    std?.subjectDetails
                                      .map(ele => ele.name)
                                      .join(', '),
                                    24
                                  )
                                : 'N/A'}
                            </td>
                            <td className='py-2 px-4 border whitespace-nowrap'>
                              {std?.studentDetails?.schoolName ?? 'N/A'}
                            </td>
                            <td className='py-2 px-4 border whitespace-nowrap'>
                              {std?.boardDetails?.name ?? 'N/A'}
                            </td>
                            <td className='py-2 px-4 border whitespace-nowrap'>
                              {std?.studentDetails?.dob !== ''
                                ? moment(std?.studentDetails?.dob).format(
                                    'DD-MMM-YYYY'
                                  )
                                : 'N/A'}
                            </td>
                            {isAction ? (
                              <>
                                <td className='py-2 px-4 border whitespace-nowrap'>
                                  {std?.status ? 'Active' : 'In-Active'}
                                </td>
                                <td className='py-2 px-4 border whitespace-nowrap flex'>
                                  <FaEdit
                                    size={24}
                                    className='hover:text-[#CD0C5C] text-gray-900'
                                    onClick={() => openModal(std)}
                                  />
                                </td>
                              </>
                            ) : null}
                          </tr>
                        ))
                      ) : (
                        <tr className='border-b text-center'>
                          Data Not Available
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}

export default StudentDetail
