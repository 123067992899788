import React from 'react'

const Button = ({ name, onClick, value }) => {
  return (
    <div>
      <button onClick={onClick}>{name} {value ? `(${value})` : ""}</button>
    </div>
  )
}

export default Button