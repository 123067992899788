/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import "./Earning.css";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const EarningTable = ({ selectCity,currentMonth }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let feeData = selector?.Fee?.creditBaseTuition?.data;
  const navigate = useNavigate();

  useEffect(() => {
    let tuitionEarning = {
      cityId: selectCity != "" ? [selectCity] : null,
    };
    if (currentMonth) {
      let momentDate = moment(currentMonth, 'YYYY-MM')
      let timestamp = momentDate.valueOf()
      tuitionEarning['month'] = timestamp
    }
    dispatch(feeAction.getCreditBaseTuitionEarning(tuitionEarning));
  }, [selectCity,currentMonth]);

  const handleFeeRecived = (data) => {
    navigate(`/app/feerecevied-cb`, { state: { feeData: data } });
  };
  const handleReferencePaid = (data) => {
    navigate(`/app/referencepaid-cb`, { state: { feeReferenceData: data } });
  };
  const handleFeeRefund = (data) => {
    navigate(`/app/feerefund-cb`, { state: { feeRefundData: data } });
  };

  return (
    <>
      <div className="m-5">
        <div className=" border border-[#D3D3D3] rounded-xl overflow-x-auto lg:block hidden">
          <table className="min-w-full bg-white rounded-xl">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-[#313131]">
                <th className="py-3 px-6 whitespace-nowrap table_head text-[#313131]">Month-Year</th>
                <th className="py-3 px-6 whitespace-nowrap table_head text-[#313131]">Total Amount Received</th>
                <th className="py-3 px-6 whitespace-nowrap table_head text-[#313131]">Total Amount Refunded</th>
                <th className="py-3 px-6 whitespace-nowrap table_head text-[#313131]">Referral Paid</th>
                <th className="py-3 px-6 whitespace-nowrap table_head text-[#313131]">Revenue</th>
              </tr>
            </thead>
            <tbody>
              {feeData &&
                feeData?.map((item, index) => (
                  <tr key={index} className="my-2  border-b border-[#D3D3D3] ">
                    <td className="px-6 py-3  text-center  text-gray-900">{moment(item.month).format("MMMM - YYYY ")}</td>
                    <td className="px-6 py-3  text-center  text-gray-900">
                      <div className="forfontsmall text-[#313131]">
                        {" "}
                        {item?.feeReceived}{" "}
                      </div>
                      <div className="underline text-[#00AFE2]">
                        <button
                          className="underline"
                          href="#"
                          onClick={() => handleFeeRecived(item)}
                        >
                          View
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-3  text-center  text-gray-900">
                      <div className="forfontsmall text-[#313131]">
                        {" "}
                        {item.feeRefunded}{" "}
                      </div>
                      <div className="underline ttext-[#00AFE2] text-[#00AFE2]">
                        <button
                          className="underline"
                          href="#"
                          onClick={() => handleFeeRefund(item)}
                        >
                          View
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-3  text-center  text-gray-900">
                      <div className="forfontsmall text-[#313131]">
                        {" "}
                        {item?.referralPaid}
                      </div>
                      <div className="underline text-[#00AFE2]">
                        <button
                          className="underline"
                          onClick={() => handleReferencePaid(item)}
                        >
                          View
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-3  text-center  text-gray-900">
                      <div className="forfontsmall text-[#313131]">
                        {item?.Revenue}{" "}
                      </div>
                      <div className="underline text-[#00AFE2]"></div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="lg:hidden block">
          {feeData &&
            feeData?.map((item, id) => {
              return (
                <>
                  <table
                    key={id}
                    className="w-full  my-4  bg-white    rounded-xl"
                  >
                    <tr className="border-b  border-gray-300">
                      <th className="text-left ps-2 py-2">
                        <span>Month-Year</span>
                      </th>
                      <td className="text-auto  py-2">
                        <div>{moment(item.month).format("MMMM - YYYY")}</div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-2 ps-2">
                        <span>Total Amount Received</span>
                      </th>
                      <td className="text-auto py-2">
                        <div className="forfontsmall text-[#313131]">
                          {item?.feeReceived}
                        </div>
                        <div className="forfontsmall text-[#313131]">
                          View
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-2 ps-2">
                        <span>Total Amount Refunded</span>
                      </th>
                      <td className="text-auto py-2">
                        <div className="forfontsmall text-[#313131]">
                          {item.feeRefunded}
                        </div>
                        <div className="forfontsmall text-[#313131]">
                          View
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-300">
                      <th className="text-left py-2 ps-2">
                        <span>Referral Paid</span>
                      </th>
                      <td className="text-auto py-2">
                        <div className="forfontsmall text-[#313131]">
                          {item?.referralPaid}
                        </div>
                        <div className="forfontsmall text-[#313131]">
                          View
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="text-left py-2 ps-2">
                        <span>Revenue</span>
                      </th>
                      <td className="text-auto py-2">
                        <div className="forfontsmall text-[#313131]">
                          {item?.Revenue}
                        </div>
                      </td>
                    </tr>
                  </table>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default EarningTable;
