/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { alertActions, parentAction, userActions } from '../../_actions'
import {
  FaAngleRight,
  FaChevronCircleRight,
  FaChevronLeft
} from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import moment from 'moment/moment'
import { useNavigate } from 'react-router'
import { FaChevronRight } from 'react-icons/fa'
import Loader from '../../components/Loader'
import { FaEye } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import { truncateText } from '../../_helpers'
import ImagePreviewModal from '../TutorToLive/Model/ImagePreviewModal'
import StudentDetail from './Model/StudentDetail'
import ViewParent from './Model/ViewParent'

const mergeAndAddStatus = (firstArray, secondArray) => {
  return secondArray.map(item => {
    const found = firstArray.find(
      firstItem => firstItem.studentId === item.studentId
    )
    return {
      ...item,
      status: !!found,
      subjectDetails: found ? found.subjectDetails : item.subjectDetails
    }
  })
}

export const DeletedParent = ({ searchText, filters, handleRefreshCount }) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [list, setList] = useState()
  const [studentListData, setStudentListData] = useState()
  const [action, setAction] = useState(false)
  const navigate = useNavigate()
  const [fullDetails, setFullDetails] = useState(false)
  const [DetailsData, setFullDetailsData] = useState()
  const [viewStudentModal, setViewStudentModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10

  useEffect(() => {
    let blockParentDetails = {
      status: 'DELETE',
      userType: 'PARENT',
      keyWord: searchText,
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      isDeleted: true,
      isManagement: false,
      ...filters
    }

    dispatch(parentAction.getUserList(blockParentDetails))
  }, [currentPage, searchText, filters])

  useEffect(() => {
    if (selector?.Parent?.parentuserList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.Parent?.parentuserList?.data?.total / pageSize)
      )
    }
    setList(
      () => selector?.Parent?.parentuserList?.data?.demoList
    )
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handleAction = id => {
    setAction(action === id ? null : id)
  }

  const handleFullDetails = data => {
    userActions.getUserByPromiseId({ id: data._id }).then(res => {
      setFullDetails(true)
      setFullDetailsData(res?.data)
    })
  }

  const handleStudent = data => {
    const studentListDetails = {
      parentId: data._id
    }

    fetchStudents(studentListDetails)
  }

  const handleViewHistory = data => {
    navigate(`/app/parentviewhistory/${data?._id}`)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')

  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }

  const handleRevokeAccount = data => {
    confirmAlert({
      title: `Revoke Account`,
      message: `Are you sure to perform this action?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            parentAction
              .revokeUserAccountByAdminPromise({ userId: data._id })
              .then(res => {
                dispatch(alertActions.success('Success.'))
              })
              .catch(err => {
                dispatch(alertActions.error('Please try again later.'))
              })
            const blockParentDetails = {
              status: 'DELETE',
              userType: 'PARENT',
              keyWord: searchText,
              fromDate: '',
              toDate: '',
              sortOrder: 'asc',
              sortBy: 'createdAt',
              pageNo: currentPage,
              size: pageSize,
              isDeleted: true,
              isManagement: false,
              ...filters
            }
            handleRefreshCount()
            dispatch(parentAction.getUserList(blockParentDetails))
          }
        },
        {
          label: 'No'
        }
      ]
    })
    setAction(null)
  }

  const fetchStudents = data => {
    let students = []
    parentAction
      .getStudentListByParentIdPromise(data)
      .then(resData => {
        students = resData.data ?? []
      })
      .catch(err => { })
    parentAction
      .getStudentListByParentIdForDemoPromise(data)
      .then(resData => {
        const list = (resData?.data || []).map(item => ({
          subjectDetails: [],
          studentId: item?._id ?? '',
          studentDetails: {
            _id: item?._id ?? '',
            studentFullName: item?.studentFullName ?? '',
            dob: item?.dob ?? '',
            gender: item?.gender ?? '',
            schoolName: item?.schoolName ?? '',
            medium: item?.medium ?? ''
          },
          classDetails: {
            _id: item?.grade?._id ?? '',
            name: item?.grade?.name ?? ''
          },
          boardDetails: item?.educationBoard ?? {}
        }))
        const mergedArray = mergeAndAddStatus(students, list)
        setStudentListData(() => mergedArray)
      })
      .catch(err => { })
    setViewStudentModal(true)
  }

  return (
    <section>
      <Loader loading={selector?.Parent?.loading} />

      <section className='lg:block hidden'>
        <div className='mt-5 border border-[#D3D3D3] rounded-xl bg-white'>
          <table className='min-w-full table-autorounded-xl overflow-x-auto'>
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='text-[#313131]'>
                <th className='px-6 py-3 whitespace-nowrap text-left text-sm text-black'>
                  Parent Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Student Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  City
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Signup Date
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {list && list.length > 0 ? (
                list?.map((item, id) => (
                  <tr
                    key={id}
                    className=' border-b border-[#D1D1D1] capitalize'
                  >
                    <td className='px-4 py-4 text-sm text-gray-900'>
                      <div className='flex items-center  gap-2'>
                        <span
                          onClick={
                            item?.image
                              ? () => handleCloseFunctionImageModel(item?.image)
                              : null
                          }
                        >
                          {item?.image && item?.image ? (
                            <img
                              className='rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]'
                              src={item?.image}
                              alt='User'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.name
                                  ? item.name
                                    .split(' ')
                                    .map(name => name.charAt(0))
                                    .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item?.name ?? 'N/A'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item?.mobNo ?? 'N/A'}
                          </p>
                          <div
                            className='flex items-center gap-1 cursor-pointer pt-1'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      <span
                        className='hover:text-[#CD0C5C] text-center flex items-center justify-center'
                        onClick={() => handleStudent(item)}
                      >
                        {' '}
                        <FaEye size={24} />
                      </span>
                    </td>

                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item?.city?.name ?? 'N/A'}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {moment(item?.createdAt).format('DD-MMM-YYYY')}
                    </td>
                    <td className='px-4 py-4 text-gray-900 relative'>
                      <span className='flex justify-center items-center'>
                        <BsThreeDotsVertical
                          onClick={() => handleAction(item._id)}
                          className='text-2xl cursor-pointer text-red-500 '
                        />
                      </span>
                      {action === item._id && (
                        <div className='absolute right-16 mt-1 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10'>
                          <div className='py-1 font-semibold text-[#023565]'>
                            <div
                              className='b px-4 py-2 cursor-pointer text-sm flex justify-between place-items-center hover:text-[#E4006F]   hover:bg-gray-100'
                              onClick={() => handleViewHistory(item)}
                            >
                              View History
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                            <div
                              className='b px-4 py-2 cursor-pointer text-sm flex justify-between place-items-center hover:text-[#E4006F]   hover:bg-gray-100'
                              onClick={() => handleRevokeAccount(item)}
                            >
                              Revoke Account
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan='7'
                    className='px-6 py-4 text-center text-gray-900 sm:px-4 sm:py-2'
                  >
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
      <section className='space-y-5 py-4 lg:hidden block'>
        {list && list.length > 0 ? (
          list?.map((item, id) => (
            <div
              key={id}
              className=' bg-white p-4 rounded-[10px] border border-[#023565]'
            >
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[14px] font-[600] text-left'>
                      Parent Name
                    </p>
                  </div>
                  <div className='w-full flex items-start'>
                    <div className='flex justify-start  gap-2 w-[95%]'>
                      <div
                        onClick={
                          item?.image
                            ? () => handleCloseFunctionImageModel(item?.image)
                            : null
                        }
                      >
                        {item && item?.image ? (
                          <img
                            alt=''
                            src={item && item?.image ? item?.image : ''}
                            className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                          />
                        ) : (
                          <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                            <span className='text-base font-semibold text-[#033565] capitalize'>
                              {' '}
                              {item?.name
                                ? item.name
                                  .split(' ')
                                  .map(name => name.charAt(0))
                                  .join('')
                                : ''}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className=''>
                        <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                          {/* {item?.name ?? 'Not Available'} */}
                          {truncateText(item?.name, 10) ?? 'N/A'}
                        </p>
                        <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                          {item?.mobNo ?? 'Not Available'}
                        </p>
                        <div className='flex items-center  gap-3 cursor-pointer'>
                          <div
                            className='flex items-center  gap-1'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-[12px] font-medium cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='relative w-[5%]'>
                      <BsThreeDotsVertical
                        size={24}
                        className='text-[#C60E6B] cursor-pointer relative'
                        onClick={() => handleAction(item._id)}
                      />{' '}
                      {action === item._id && (
                        <div className='absolute right-4 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10'>
                          <div className='py-1 font-semibold text-[#023565]'>
                            <div
                              className='b px-4 py-2 cursor-pointer text-sm flex justify-between place-items-center hover:text-[#E4006F]   hover:bg-gray-100'
                              onClick={() => handleViewHistory(item)}
                            >
                              View History
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                            <div
                              className='b px-4 py-2 cursor-pointer text-sm flex justify-between place-items-center hover:text-[#E4006F]   hover:bg-gray-100'
                              onClick={() => handleRevokeAccount(item)}
                            >
                              Revoke Account
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Student Name
                    </p>
                  </div>
                  <div className='w-full'>
                    <p
                      className='text-[14px] font-[700] text-[#023565]'
                      onClick={() => handleStudent(item)}
                    >
                      View
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] capitalize'>
                      {item?.city?.name ?? 'No City'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Signup Date
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.createdAt
                        ? moment(item.createdAt).format('DD-MMM-YYYY')
                        : 'No Date'}
                    </p>
                  </div>
                </div>
              </>
            </div>
          ))
        ) : (
          <p className='text-center'>Data Not Found</p>
        )}
      </section>
      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2'>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      <ViewParent
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
      />
      <StudentDetail
        setViewStudentModal={setViewStudentModal}
        viewStudentModal={viewStudentModal}
        setStudentListData={setStudentListData}
        studentListData={studentListData}
      />

      <ImagePreviewModal
        handleClose={handleCloseFunctionImageModel}
        showWarning={isModalVisible}
        image={image}
      />
    </section>
  )
}
