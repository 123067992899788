/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, parentAction, userActions } from "../../_actions";
import { FaChevronCircleRight, FaChevronLeft, FaChevronRight, FaEye } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment/moment";
import { cityAction } from "../../_actions/city.action";
import { MdOutlineClose } from "react-icons/md";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import ViewParent from "./Model/ViewParent";
import ImagePreviewModal from "../../pages/Tutor-Profile/Model/ImagePreview";
import UpdateParent from "./Model/UpdateParent";
import StudentDetail from "./Model/StudentDetail";
import AddEditStudentModel from "../../pages/Parent-Student-Profile/model/AddEditStudentModel";

const initialState = {
  address: "",
  email: "",
  id: "",
  mobNo: "",
  name: "",
  pinCode: "",
  secondaryNo: "",
  whatsappNo: ""
}

const studentInitialState = {
  studentFullName: '',
  dob: '',
  grade: { label: 'Select Grade', value: '' },
  schoolName: '',
  educationBoard: { label: 'Select Board', value: '' },
  medium: '',
  gender: '',
  studentId: ''
}

const mergeAndAddStatus = (firstArray, secondArray) => {
  return secondArray.map(item => {
    const found = firstArray.find(
      firstItem => firstItem.studentId === item.studentId
    )
    return {
      ...item,
      status: !!found,
      subjectDetails: found ? found.subjectDetails : item.subjectDetails
    }
  })
}

const InActiveParent = ({ searchText, filters, handleRefreshCount }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [action, setAction] = useState(null);
  const [blockedData, setBlockedData] = useState({});
  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [blockedPopUp, setBlockPopUp] = useState(false);
  const [fullDetails, setFullDetails] = useState(false);
  const [DetailsData, setFullDetailsData] = useState();
  const [cityData, setCityData] = useState([]);
  const [parentUpdateModal, setParentUpdateModal] = useState(false);
  const [parentUpdateData, setParentUpdateData] = useState(initialState);
  const [errors, setErrors] = useState({})
  const menuRef = useRef(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10;
  const [list, setList] = useState([])
  const [isOpenAddEditModel, setIsAddEditModel] = useState(false)
  const [addEditData, setAddEditData] = useState({
    ...studentInitialState,
    parentId: ''
  })

  const [studentListData, setStudentListData] = useState()


  useEffect(() => {
    const inactiveParentDetails = {
      status: "INACTIVE",
      userType: "PARENT",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      ...filters
    };

    dispatch(parentAction.getUserList(inactiveParentDetails));
    dispatch(cityAction.cityGet({
      isManagement: false
    }));
  }, [filters, currentPage]);

  useEffect(() => {
    setList(() => selector?.Parent?.parentuserList?.data?.demoList || [])
    if (selector?.Parent?.parentuserList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.Parent?.parentuserList?.data?.total / pageSize)
      )
    }
    setCityData(() => selector?.Citys?.cityGetList);
  }, [selector]);

  const handleAction = (id) => {
    setAction(action === id ? null : id);
  };

  const handleBlockParent = (data) => {
    const userData = {
      status: "BLOCK",
      id: data._id,
      name: data.name,
      reason: ""
    };
    setBlockedData(userData);
    setBlockPopUp(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errObject = {}

    if (!blockedData.reason.trim()) {
      errObject.reason = "Please enter block reason."
    } else if (blockedData.reason.trim().length < 5) {
      errObject.reason = "Please enter a valid block reason."
    }
    setErrors(errObject);

    if (Object.keys(errObject).length != 0) {
      return false
    }

    const listData = {
      status: "INACTIVE",
      userType: "PARENT",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      ...filters
    };

    const reqData = {
      id: blockedData.id,
      status: blockedData.status,
      reason: blockedData.reason
    };

    dispatch(parentAction.blockByAdmin(reqData, listData));
    setBlockPopUp(false);
    handleRefreshCount();
    setBlockedData({});
  };

  const handleStudent = (data) => {
    const studentListDetails = {
      parentId: data._id
    }
    setAddEditData((prev) => ({
      ...prev,
      parentId: data._id
    }))
    fetchStudents(studentListDetails)
  };

  const handleFullDetails = (data) => {
    userActions.getUserByPromiseId({ id: data._id }).then((res) => {
      setFullDetails(true);
      setFullDetailsData(res?.data);
    })
  };

  const handleParentUpdate = (data) => {
    const reqData = {
      id: data?._id,
      address: data?.address || "",
      email: data?.email || "",
      mobNo: data?.mobNo || "",
      name: data?.name || "",
      secondaryNo: data?.secondaryNo || "",
      whatsappNo: data?.whatsappNo || "",
      pinCode: data?.pinCode && Array.isArray(data.pinCode) && data.pinCode.length > 0 ? data.pinCode[0] : "",
      city: data?.city?.id || ""
    }
    setParentUpdateData(reqData);
    setParentUpdateModal(true);
  };

  const handleOnChangeUpdate = (e) => {
    const { name, value } = e.target;
    if (name == "city") {
      setParentUpdateData((prev) => ({
        ...prev,
        pinCode: "",
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        pinCode: "",
        [name]: "",
      }))

    } else {
      setParentUpdateData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }))
    }
  };

  const validateForm = () => {
    let errorsData = {}

    // Validate mobile number
    if (!parentUpdateData.mobNo) {
      errorsData.mobNo = 'Mobile number is required';
    } else if (!/^[0-9]{10}$/.test(parentUpdateData.mobNo)) {
      errorsData.mobNo = 'Mobile number must be a valid 10-digit number';
    }

    // Validate name
    if (!parentUpdateData.name) {
      errorsData.name = 'Name is required';
    } else if (parentUpdateData.name.length < 2) {
      errorsData.name = 'Name must be at least 2 characters long';
    } else if (parentUpdateData.name.length > 50) {
      errorsData.name = 'Name must be at most 50 characters long';
    }

    // Validate address
    if (!parentUpdateData.address) {
      errorsData.address = 'Address is required';
    } else if (parentUpdateData.address.length < 5) {
      errorsData.address = 'Address must be at least 5 characters long';
    } else if (parentUpdateData.address.length > 100) {
      errorsData.address = 'Address must be at most 100 characters long';
    }

    // Validate city
    if (!parentUpdateData.city) {
      errorsData.city = 'City is required';
    }

    // Validate pincode
    if (!parentUpdateData.pinCode || parentUpdateData.pinCode.length === 0) {
      errorsData.pinCode = 'At least one pin code is required';
    } else if (!/^[0-9]{6}$/.test(parentUpdateData.pinCode)) {
      errorsData.pinCode = 'Pin code must be a valid 6-digit number';
    }

    setErrors(errorsData)
    return Object.keys(errorsData).length === 0;
  }

  const handleUpdateParent = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return
    }

    const reqData = {
      ...parentUpdateData,
      pinCode: parentUpdateData.pinCode != "" ? [parentUpdateData.pinCode] : [],
    };

    const listData = {
      status: "INACTIVE",
      userType: "PARENT",
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      isManagement: false,
      ...filters
    };

    dispatch(parentAction.updateParentByAdmin(reqData, listData));
    setParentUpdateModal(false);
    setParentUpdateData(initialState)
  };

  const handleViewHistory = (data) => {
    navigate(`/app/parentviewhistory/${data?._id}`)
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setAction(null)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const fetchStudents = data => {
    let students = []
    parentAction
      .getStudentListByParentIdPromise(data)
      .then(resData => {
        students = resData.data ?? []
      })
      .catch(err => { })
    parentAction
      .getStudentListByParentIdForDemoPromise(data)
      .then(resData => {
        const list = (resData?.data || []).map(item => ({
          subjectDetails: [],
          studentId: item?._id ?? '',
          studentDetails: {
            _id: item?._id ?? '',
            studentFullName: item?.studentFullName ?? '',
            dob: item?.dob ?? '',
            gender: item?.gender ?? '',
            schoolName: item?.schoolName ?? '',
            medium: item?.medium ?? ''
          },
          classDetails: {
            _id: item?.grade?._id ?? '',
            name: item?.grade?.name ?? ''
          },
          boardDetails: item?.educationBoard ?? {}
        }))
        const mergedArray = mergeAndAddStatus(students, list)
        setStudentListData(() => mergedArray)
      })
      .catch(err => { })
    setViewStudentModal(true)
  }

  const handleOpenAddEditModal = data => {
    if (Object.keys(data).length) {
      const values = {
        studentFullName: data?.studentDetails?.studentFullName,
        dob: data?.studentDetails?.dob,
        grade: {
          label: data?.classDetails?.name,
          value: data?.classDetails?._id
        },
        schoolName: data?.studentDetails?.schoolName,
        educationBoard: {
          label: data?.boardDetails?.name,
          value: data?.boardDetails?._id
        },
        medium: data?.studentDetails?.medium,
        gender: data?.studentDetails?.gender,
        studentId: data.studentId
      }
      setAddEditData(prev => ({
        ...prev,
        ...values
      }))
      setIsAddEditModel(true)
    } else {
      setIsAddEditModel(true)
    }
    setViewStudentModal(false)
  }

  const handleAddEditDataChange = e => {
    const { name, value } = e.target
    setAddEditData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleCloseAddEditModal = () => {
    fetchStudents({ parentId: addEditData.parentId })
    setAddEditData(prev => ({
      ...prev, // Spread the previous state
      ...studentInitialState
    }))
    setIsAddEditModel(false)
    // setViewStudentModal(true);
  }

  const handleSubmitAddEditModal = () => {
    let studentData = {
      ...addEditData,
      educationBoard: addEditData.educationBoard.value,
      grade: addEditData.grade.value
    }

    parentAction
      .addEditStudentByAdminPromise(studentData)
      .then(res => {
        fetchStudents({ parentId: addEditData.parentId })

        setAddEditData(prev => ({
          ...prev, // Spread the previous state
          ...studentInitialState // Reset to initial state
        }))

        // Close the modal
        setIsAddEditModel(false)
        dispatch(alertActions.success("Success."))
      })
      .catch(err => {
        dispatch(
          alertActions.error(
            err === 'Server error.'
              ? 'Please try again later.'
              : 'Student name already exist.'
          )
        ) // Show error message to the user
        fetchStudents({ parentId: addEditData.parentId })

        setAddEditData(prev => ({
          ...prev, // Spread the previous state
          ...studentInitialState // Reset to initial state
        }))

        // Close the modal
        setIsAddEditModel(false)
      })
  }

  return (
    <>
      <Loader loading={selector?.userList?.loading} />

      <section className="lg:block hidden py-10">
        <div className="border border-[#D3D3D3] rounded-xl overflow-x-auto">
          <table className="min-w-full bg-white rounded-xl">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-[#313131]">
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Parent Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Student Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  City
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Signup Date
                </th>

                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {list && list.length ?
                list?.map((item, id) => (
                  <tr key={id} className="border-b border-[#D1D1D1] capitalize">
                    <td className="flex items-center gap-4 px-6 py-4 text-sm text-gray-900 ">
                      <div className='flex items-center  gap-2'>
                        <span
                          onClick={
                            item?.image
                              ? () => handleCloseFunctionImageModel(item?.image)
                              : null
                          }
                        >
                          {item?.image && item?.image ? (
                            <img
                              className='rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]'
                              src={item?.image}
                              alt='User'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.name
                                  ? item.name
                                    .split(' ')
                                    .map(name => name.charAt(0))
                                    .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item?.name ?? 'N/A'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item?.mobNo ?? 'N/A'}
                          </p>
                          <div
                            className='flex items-center gap-1 cursor-pointer pt-1'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 text-gray-900">
                      <span
                        className="cursor-pointer underline font-bold hover:text-[#CD0C5C]"
                        onClick={() => handleStudent(item)}
                      >
                        <FaEye size={24} />

                      </span>
                    </td>
                    <td className="px-6 py-4 text-gray-900">
                      {item?.city?.name ?? "No city"}
                    </td>
                    <td className="px-6 py-4 text-gray-900">
                      {item?.createdAt
                        ? moment(item.createdAt).format("YYYY-MM-DD")
                        : "No Date"}
                    </td>

                    <td className="px-6 py-4 text-gray-900">
                      <BsThreeDotsVertical
                        className=" text-[#C60E6B] cursor-pointer"
                        onClick={() => handleAction(item._id)}
                      />
                      {action === item._id && (
                        <div className="absolute right-0 mt-2 w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10" ref={menuRef}>
                          <div className="py-1 font-semibold">
                            <span
                              className="block px-4 py-2 text-sm text-pink-600 hover:bg-gray-100" onClick={() => handleParentUpdate(item)}
                            >
                              View & Update Info
                            </span>
                            <span
                              className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                              onClick={() => handleBlockParent(item)}
                            >
                              Block Parent
                            </span>
                            <span
                              className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                              onClick={() => handleViewHistory(item)}
                            >
                              View History
                            </span>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
                : <td className="text-center">Data not found.</td>}
            </tbody>
          </table>
        </div>
      </section>

      {blockedPopUp && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[600px]">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => setBlockPopUp(false)}
            >
              <span><MdOutlineClose /></span> Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Are you sure. Do you want to block "<span className="text-red-500 underline">{blockedData?.name || "User"}</span>" ?</h2>
              </div>
              <div className="relative lg:h-[200px] overflow-y-auto">
                <form className="space-y-2 p-4" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="subjectName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Blocked Reason:
                    </label>
                    <textarea
                      type="text"
                      id="subjectName"
                      value={blockedData.reason}
                      onChange={(e) => {
                        setBlockedData((prev) => ({
                          ...prev,
                          reason: e.target.value
                        }))
                        setErrors((prev) => ({
                          ...prev,
                          reason: ""
                        }))
                      }}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                  </div>
                  {errors.reason && <p className="text-red-500">{errors.reason}</p>}
                  <div className="flex justify-center items-center pt-2">
                    <button
                      type="submit"
                      className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                    >
                      Blocked
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <ViewParent
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
      />

      <UpdateParent
        open={parentUpdateModal}
        closeModal={() => { setErrors(); setParentUpdateData(initialState); setParentUpdateModal(false) }}
        formData={parentUpdateData}
        handleChange={handleOnChangeUpdate}
        errors={errors}
        cityList={cityData}
        handleSubmit={handleUpdateParent}
      />

      <ImagePreviewModal
        handleClose={handleCloseFunctionImageModel}
        showWarning={isModalVisible}
        image={image}
      />

      <StudentDetail
        setViewStudentModal={setViewStudentModal}
        viewStudentModal={viewStudentModal}
        setStudentListData={setStudentListData}
        studentListData={studentListData}
        isAction={true}
        openModal={handleOpenAddEditModal}
      />

      <AddEditStudentModel
        isView={isOpenAddEditModel}
        data={addEditData}
        handleChange={handleAddEditDataChange}
        handleCloseModal={handleCloseAddEditModal}
        handleSubmitModal={handleSubmitAddEditModal}
      />

      <section className="space-y-5 py-10 lg:hidden block">
        {list && list.length > 0 ? (
          list?.map((item, id) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Parent Name	</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          item?.image ??
                          "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                        }
                        alt="User"
                      />
                      <div>
                        <div className="text-xs font-medium text-black ">{item?.name ?? "No Data"}</div>
                        <div className="text-xs text-gray-500">{item?.mobNo || 'No Number'}</div>
                        <FaChevronCircleRight
                          className="text-[#023565] cursor-pointer"
                          onClick={() => handleFullDetails(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Student Name</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' onClick={() => handleStudent(item)}>view</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.city?.name ?? "No City"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Signup Date</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.createdAt ? moment(item.createdAt).format("DD-MMM-YYYY") : "No Date"}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Inactive Reason</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.reason ?? "No Resion"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] '><BsThreeDotsVertical size={24}
                      className="text-[#C60E6B] cursor-pointer relative"
                      onClick={() => handleAction(item._id)}
                    />  {action === item._id && (
                      <div className="absolute right-50  w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40">
                        <div className="py-1 font-semibold">
                          <button
                            className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                            onClick={() => handleParentUpdate(item)}                          >
                            View & Update Info
                          </button>
                          <button
                            className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                            onClick={() => handleBlockParent(item)}
                          >
                            Block Parent
                          </button>
                          <button
                            className="block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100"
                            onClick={() => handleViewHistory(item)}
                          >
                            View History
                          </button>
                        </div>
                      </div>
                    )}</p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>

      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2'>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}

    </>
  );
};

export default InActiveParent;
