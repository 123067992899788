import React from 'react'
import { MdOutlineClose } from 'react-icons/md';

const UpdateParent = ({ open, closeModal, formData, handleChange, errors, cityList, handleSubmit }) => {

    return open && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[850px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={closeModal}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Update Parent</h2>
                        </div>
                        <div className="relative lg:h-[550px] overflow-y-auto">
                            <form onSubmit={handleSubmit}>
                                <div className="p-4 space-y-2">
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="subjectName"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Parent Name:
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formData.name || ""}
                                            onChange={handleChange}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.name && <span className="text-red-500">{errors.name}</span>}
                                    </div>

                                    <div className="space-y-1">
                                        <label
                                            htmlFor="subjectName"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Mobile Number:
                                        </label>
                                        <input
                                            type="text"
                                            name="mobNo"
                                            value={formData.mobNo || ""}
                                            onChange={handleChange}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.mobNo && <span className="text-red-500">{errors.mobNo}</span>}

                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="subjectName"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Email:
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={formData.email || ""}
                                            onChange={handleChange}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.email && <span className="text-red-500">{errors.email}</span>}

                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="subjectName"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Whatsapp Number:
                                        </label>
                                        <input
                                            type="text"
                                            id="subjectName"
                                            name="whatsappNo"
                                            value={formData.whatsappNo || ""}
                                            onChange={handleChange}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label
                                            htmlFor="subjectName"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Address:
                                        </label>
                                        <textarea
                                            type="text"
                                            name="address"
                                            value={formData.address || ""}
                                            onChange={handleChange}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.address && <span className="text-red-500">{errors.address}</span>}

                                    </div>

                                    <div className="space-y-1">
                                        <label
                                            htmlFor="city"
                                            className="block text-sm font-medium text-gray-700 capitalize"
                                        >
                                            City:
                                        </label>
                                        <select
                                            id="city"
                                            name="city"
                                            value={formData.city || ""}
                                            onChange={handleChange}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md capitalize"
                                        >
                                            <option value="">Choose a City</option>
                                            {Array.isArray(cityList) && cityList.length > 0 && cityList.map((city, index) => (
                                                <option key={city._id} value={city._id}>
                                                    {city.name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors?.city && <span className="text-red-500">{errors.city}</span>}

                                    </div>

                                    <div className="space-y-1">
                                        <label
                                            htmlFor="pinCode"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Pin code:
                                        </label>
                                        <select
                                            id="pinCode"
                                            name="pinCode"
                                            value={formData.pinCode || ""}
                                            onChange={handleChange}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        >
                                            <option value="">Choose a Pin code</option>
                                            {formData.city && Array.isArray(cityList) &&
                                                cityList.length > 0 && cityList.find((city, index) => String(city._id) === String(formData.city))?.pinCode?.map((pin, index) => {
                                                    return (
                                                        <option value={pin} className="text-black" key={index}>
                                                            {pin}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </select>
                                        {errors?.pinCode && <span className="text-red-500">{errors.pinCode}</span>}

                                    </div>
                                    <div className="mt-4 flex justify-center pt-4">
                                        <button
                                            className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                            type="submit"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default UpdateParent