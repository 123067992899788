/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import EarningTable from "./EarningTable";
import "./Earning.css";
import EarningCbHeader from "./EarningCbHeader";
import { feeAction } from "../../../_actions/fee.action";

const Earning = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const feeData = selector?.Fee?.creditBaseTuition?.data;
  const [selectCity, setSelectCity] = useState('')
  const [currentMonth, setCurrentMonth] = useState()

  const totalFeePaid =
    feeData?.reduce((acc, item) => acc + item?.feePaid, 0) || 0;
  const totalFeePainding =
    feeData?.reduce((acc, item) => acc + item?.feePending, 0) || 0;
  const totalRefrencePaid =
    feeData?.reduce((acc, item) => acc + item?.referencePaid, 0) || 0;
  const totalRefrailPaid =
    feeData?.reduce((acc, item) => acc + item?.referralPaid, 0) || 0;
  const totalFeeRefund =
    feeData?.reduce((acc, item) => acc + item?.feeRefunded, 0) || 0;
  const totalFeeRecived =
    feeData?.reduce((acc, item) => acc + item?.feeReceived, 0) || 0;

  const handleDateSearch = e => {
    // let dateID = e.target.value != "" ? e.target.value : moment().format("YYYY-MM")
    let dateID = e.target.value !== '' ? e.target.value : ''
    setCurrentMonth(dateID)
  }

  return (
    <>
      <EarningCbHeader selectCity={selectCity} setSelectCity={setSelectCity} handleDateSearch={handleDateSearch} currentMonth={currentMonth} />
      <section className="overflow-hidden overflow-y-auto">
        <div className="m-6 overflow-auto">
          <div className="grid grid-cols-12  gap-4 forMobile_view">
            <div className=" col-span-3 bg-white rounded-3xl py-4 border ">
              <p className="text-center text-[#023565]">Total Fee Received</p>
              <p className="text-center table_head ">{totalFeeRecived}</p>
            </div>
            <div className=" col-span-3 bg-white rounded-3xl py-4 border">
              <p className="text-center text-[#023565]">Total Fee Paid</p>
              <p className="text-center table_head">{totalFeePaid}</p>
            </div>
            <div className=" col-span-3 bg-white rounded-3xl py-4 border">
              <p className="text-center text-[#023565]">Total Fee Pending</p>
              <p className="text-center table_head">{totalFeePainding}</p>
            </div>
            <div className=" col-span-3 bg-white rounded-3xl py-4 border">
              <p className="text-center text-[#023565]">Total Reference Paid</p>
              <p className="text-center table_head">{totalRefrencePaid}</p>
            </div>
          </div>

        </div>
        <div className="">
          <EarningTable selectCity={selectCity} currentMonth={currentMonth} />
        </div>
      </section>
    </>
  );
};

export default Earning;
