import React, { useEffect, useState } from "react";
import { userListAction } from "../../_actions"
import Button from "./Button";
import { ActiveParent } from "./ActiveParent";
import InActiveParent from "./InActiveParent";
import BlockedParent from "./BlockedParent";
import ParrentCbheader from "./ParrentCbheader";
import ParentTutorFilter from "../Filters/ParentTutorFilter";
import { DeletedParent } from "./DeletedParent";
import { useLocation, useNavigate } from "react-router";

const ParentStudentsProfile = () => {
  const buttonName = ["Active Parent", "Inactive Parent", "Blocked Parent", "Deleted Parent"];
  const [searchText, setSearchText] = useState("");
  const [active, setActive] = useState("Active Parent")
  const [counts, setCounts] = useState({ "active": 0, "inActive": 0, "blocked": 0, "deleted": 0 })
  const [filters, setFilters] = useState({
    pinCode: ""
  })

  const [headerCity, setHeaderCity] = useState("")
  const [refreshCount, setRefreshCount] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const handleClick = (data) => {
    setActive(data)
    setSearchText('')
    navigate(`?tab=${getLabel(data)}`)

  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const getValue = (label) => {
    switch (label) {
      case "Active Parent":
        return counts.active || 0;
      case "Inactive Parent":
        return counts.inActive || 0;
      case "Blocked Parent":
        return counts.blocked || 0;
      case "Deleted Parent":
        return counts.deleted || 0;
      default:
        return 0; // Default value if the category is not found
    }
  }

  const getLabel = (label) => {
    switch (label) {
      case "Active Parent":
        return "active-parent";
      case "Inactive Parent":
        return "inactive-parent";
      case "Blocked Parent":
        return "blocked-parent";
      case "Deleted Parent":
        return "deleted-parent";
      case "active-parent":
        return "Active Parent";
      case "inactive-parent":
        return "Inactive Parent";
      case "blocked-parent":
        return "Blocked Parent";
      case "deleted-parent":
        return "Deleted Parent";
      default:
        return ""; // Default value if the category is not found
    }
  }

  useEffect(() => {
    userListAction.getParentCountByPromise({ cityId: headerCity !== "" ? [headerCity] : [], isManagement: false })
      .then(res => {
        setCounts(res.data)
      })
      .catch(error => setCounts({ "active": 0, "inActive": 0, "blocked": 0, "deleted": 0 }))
  }, [headerCity, refreshCount])

  const handleRefreshCount = () => {
    setRefreshCount(!refreshCount)
  }

  useEffect(() => {

    const tab = queryParams.get('tab');

    let newUrl = '';
    if (tab) {
      newUrl = '?';
      if (tab) newUrl += `tab=${tab}`;
      setActive(getLabel(tab))
    } else {
      newUrl = '?tab=active-parent';
    }

    // Navigate only if the URL needs to be updated
    if (location.search !== newUrl) {
      navigate(newUrl);
    }

  }, [])

  return (
    <>
      <ParrentCbheader handleSearch={handleSearch} searchText={searchText} headerCity={headerCity} setHeaderCity={setHeaderCity} />

      <section className="overflow-hidden overflow-y-auto">
        <div className="m-5">
          <section className="flex gap-3">
            {buttonName.map((item, index) => (
              <div key={index} onClick={() => handleClick(item)} className={` px-4 py-2 rounded-full lg:text-sm text-xs font-semibold ${active === item ? 'bg-[#023565] text-white' : 'bg-[#9C9EAB] text-white'}`}>
                <Button name={item} value={getValue(item)} />
              </div>
            ))}
          </section>

          <ParentTutorFilter filters={{ ...filters, cityId: headerCity }} setFilters={setFilters} />

          <section>
            {active === "Active Parent" && <ActiveParent searchText={searchText} filters={{ ...filters, cityId: headerCity ? [headerCity] : [] }} handleRefreshCount={handleRefreshCount} />}
            {active === "Inactive Parent" && <InActiveParent searchText={searchText} filters={{ ...filters, cityId: headerCity ? [headerCity] : [] }} handleRefreshCount={handleRefreshCount} />}
            {active === "Blocked Parent" && <BlockedParent searchText={searchText} filters={{ ...filters, cityId: headerCity ? [headerCity] : [] }} handleRefreshCount={handleRefreshCount} />}
            {active === "Deleted Parent" && <DeletedParent searchText={searchText} filters={{ ...filters, cityId: headerCity ? [headerCity] : [] }} handleRefreshCount={handleRefreshCount} />}
          </section>
        </div>
      </section>
    </>

  );
};

export default ParentStudentsProfile;
